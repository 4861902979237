import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default function (){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let refresh = null;

    const checkLogin = () => {
        // if store.loggedIn is false
        if(store.state.loggedIn === false){
            router.push('/');
        }
        else{
            if(route.name == 'login'){
                router.push('/home');
            }
            if(!store.state.refresher){
                //console.log(`refresher not set, setting it...`)
                store.dispatch('refreshEvent', store.state.event.id);
                refresh = setInterval(function() {
                    store.dispatch('refreshEvent', store.state.event.id);
                    //console.log('refreshed event details...');
                }, 15 * 1000);
                store.commit('setRefresher', true);
                console.log('refresher set');
            }
        }

        if(store.state.refreshError){
            stopRefresh(refresh);
        }
    }

    function stopRefresh () { 
        clearInterval(refresh);
        refresh = null; 
        store.commit('setRefresher', false)
    }

    return {
        checkLogin, stopRefresh
    }
}