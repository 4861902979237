<template>
    <header>
        <h3 class="text-white">Jubatus Voting Events</h3>
    </header>
    <main id="login-main">
        <section>
            <div class="mb-1" id="event-logo-container">
                <img class="mt-1" width="225" height="225" id="event-logo" src="../assets/img/default.png" alt="event-logo" />
            </div>
            <div id="form-header-text" class="mb-1">
                <p class="text-white">Enter the required details to login and vote.
                </p>
            </div>
        </section>
        <section>
            <form id="login-form" class="w-70" @submit="login" method="post">
                <div class="form-group text-white mb-1">
                    <label for="email">Email address:</label>
                    <input type="email" name="email" id="email" v-model="voter.email" @change="trimInput" autocomplete="off" required/>
                </div>
                <div class="form-group text-white mb-1">
                    <label for="auth_code">Voting Authentication Code:</label>
                    <input type="text" maxlength="300" max="300" name="auth_code" id="auth_code" @change="trimInput" v-model="voter.auth_code" autocomplete="off" required/>
                </div>
                <div v-if="error" class="alert alert-danger">
                    <p>{{ alertText }}</p>
                </div>
                <div v-if="success" class="alert alert-primary">
                    <p>{{ alertText }}</p>
                </div>
                <div class="form-group mt-2">
                    <button :disabled="loading" type="submit" id="enter-btn" class="btn btn-light" >{{ loading ? 'Loading..': 'Enter' }}</button>
                </div>
            </form>
            <h4 style="padding: 2rem; text-decoration: none; line-height: 1.5rem;" class="text-white">
            Tip: You can login to app directly by clicking on the voting link sent to your email address after you registered.
            </h4>
        </section>
    </main>
    <section id="login-footer" class="footer text-white">
        Jubatus Technologies  &copy; 2022. All rights reserved.
    </section>
</template>

<script>
import { onMounted, reactive, ref } from "vue"
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useUser from "../mixins/useUser";

export default {
    setup(){
        const error = ref(false);
        const success = ref(false);
        const loading = ref(false);
        const alertText = ref('');
        const voter = reactive({
            email: '',
            auth_code: '',
        });
        const store = useStore();
        const router = useRouter();

        onMounted(async() => {
            document.getElementById('email').focus();
            document.title = 'Jubatus Event Voting App - Login';
            const {checkLogin} = useUser();
            checkLogin();
            document.getElementById('email').focus();
        });

        async function login(e) {
            e.preventDefault();

            loading.value = true;

            if(voter.email == '' ||  voter.auth_code == ''){
                return;
            }

            voter.email = voter.email.trim();
            voter.auth_code = voter.auth_code.trim();

            await store.dispatch('login', voter)
            .then(function(res){
                loading.value = false;
                const  [loggedIn, message] = res;

                if(loggedIn){
                    success.value = true;
                    alertText.value = 'Success, loading dashboard...';
                    router.push('/home');
                }
                else{
                    error.value = true;
                    if(message.toLowerCase().indexOf('network') > -1){
                        alertText.value = message + ". Ensure you have an active internet connection.";
                    }
                    else{
                        alertText.value = message;
                    }
                }
            })
            .catch(function(e){
                error.value = true;
                alertText.value = e.message + ". Reload the page and try again after 1 minute."; 
                console.log(e.message);
            });
        }

        function trimInput(e){
            e.target.value = e.target.value.trim();
        }

        return {
            error,
            success,
            alertText,
            voter,
            login,
            trimInput,
            loading
        }
    }
}
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/forms.css';
@import '../assets/css/login.css';
</style>