import { createRouter, createWebHistory } from "vue-router";
import { defineAsyncComponent } from "vue";
import Register from '@/views/Register.vue';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import Position from '@/views/Position.vue';
import Vote from '@/views/Vote.vue';
import AboutCandidate from '@/views/AboutCandidate.vue';

const routes = [
    {
        path: '/register/:event_code',
        name: 'register',
        component: Register,
    },
    {
        path: '/register',
        name: 'invalid',
        component: Register,
    },
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/auth/:voter/:event/:authcode',
        name:'authenticator',
        component: Home,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/vote',
        name: 'vote',
        component: Vote,
    },
    {
        path: '/positions',
        name: 'positions',
        component: Position,
    },
    {
        path: '/about_candidate/:id',
        name: 'about',
        component: AboutCandidate,
    },
    {
        path: '/help',
        name: 'help',
        component: defineAsyncComponent(() => import('@/views/HelpView.vue')),
    },
    { //default route for 404 not found
        path: '/:pathMatch(.*)*',
        name: '404',
        component: defineAsyncComponent(() => import('@/views/NotFound.vue')),
    },
];

const router  =  createRouter({
    history: createWebHistory(),
    routes,
});

export default router;