<template>
    <header>
        <h3 class="text-white">Jubatus Voting Events</h3>
    </header>
    <main id="register-main" v-if="event.id">
        <section>
            <div class="mb-1" id="event-logo-container">
                <img class="mt-1" id="event-logo" :src="`${base_url}${event.icon}`" alt="event-logo" />
            </div>
            <div id="form-header-text" class="mb-1">
                <p class="text-white">Enter the required details to register for the voting event:
                    <br>({{event.title}})
                </p>
            </div>
        </section>
        <section>
            <form id="login-form" class="w-70" @submit.prevent="register">
                <div class="form-group text-white mb-1">
                    <label for="email">Email address:</label>
                    <input type="email" id="email" v-model="voter.email" @change="trimInput" autocomplete="off" required/>
                </div>
                <div class="form-group text-white mb-1">
                    <label for="unique_id" style="text-transform: capitalize;">Your {{event.user.company}} {{event.unique_desc}}:</label>
                    <input type="text" id="unique_id" v-model="voter.unique_id" required/>
                </div>
                <div class="alert alert-danger" v-if="error">
                    <p>{{ alertText }}</p>
                </div>
                <div class="alert alert-success" v-if="success">
                    <p>{{ alertText }}</p>
                </div>
                <div class="form-group mt-2">
                    <input v-show="!success" type="submit" style="margin: 0 auto; box-shadow: 1px 1px 2px 1px rgba(0,0,0,.25);" value="Register to Vote" class="btn btn-light" />
                </div>
            </form>
        </section>
    </main>
    <div v-if="loadError" class="alert alert-danger">
        <p>
            Unable to load your event. Kindly use the link sent to your email. If you have copy-pasted the link, make sure you included all the characters.
            <br/><button class="btn" @click="init">Retry</button>
        </p>
    </div>
    <section class="footer text-white">
        Jubatus Technologies  &copy; 2022. All rights reserved.
    </section>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    setup(){
        const route = useRoute();
        const store = useStore();
        const base_url = process.env.VUE_APP_BASE_URL;
        const voter = reactive({
            email: '',
            unique_id: ''
        });
        const error = ref(false);
        const success = ref(false);
        const alertText = ref('');

        onMounted(async() => {
            document.title = 'Jubatus Event Voting App - Register';

            if(route.params.event_code == undefined){
                alert('Kindly access the voting app via the voting link that was sent to you via email!');
                
                return;
            }

            await init();

            document.getElementById('email').focus();
        });

        async function init(){
            await store.dispatch('getEventDetails', route.params.event_code);
            
            document.body.style.background = 'var(--bg-color)';
        }

        const register = async () => {
            if(voter.email == '' || voter.unique_id == '') return;

            //trim user inputs
            voter.email = voter.email.trim();
            voter.unique_id = voter.unique_id.trim();

            const [res, message] = await store.dispatch('registerVoter', {...voter, eventId: store.state.event.id});

            if(!res){
                error.value = true;
                alertText.value = message + ' Failed to register, kindly try again later or email support at support@jubatus.co.ke.';
                setTimeout(() => error.value = false, 10 * 1000);
            }
            else{
                success.value = true;
                alertText.value = message;
            }
        }

        function trimInput(e){
            e.target.value = e.target.value.trim();
        }
        
        return {
            init,
            event: computed(() => store.state.event), 
            base_url, 
            loadError: computed(() => store.state.eventError),
            register,
            voter,
            error,
            alertText,
            success,
            trimInput
        }
    }
}
</script>

<style scoped>
@import '../assets/css/register.css';
@import '../assets/css/common.css';
@import '../assets/css/forms.css';
</style>