<template>
    <footer>
        <nav id="menu">
            <router-link to="/home" :class="[props.path == '/home' ? 'active': '']"><i class="fas fa-home"></i> Home</router-link>
            <router-link to="/positions" :class="[props.path == '/positions' ? 'active': '']"><i class="fas fa-portrait"></i>Positions</router-link>
            <router-link to="/vote" :class="[props.path == '/vote' ? 'active': '']"><i class="fas fa-vote-yea"></i>Vote</router-link>
            <a href="/logout" @click.prevent="logout"><i class="fas fa-sign-out-alt"></i>Logout</a>
            <a href="/help"><i class="fas fa-info-circle"></i>Help</a>
        </nav>
    </footer>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useUser from "../mixins/useUser";

export default {
    props: {
        path: String,
    },
    setup(props){
        const store = useStore();
        const router = useRouter();
        const { stopRefresh } = useUser();

        function logout(){
            stopRefresh();
            store.commit('clearState');
            router.push('/');
        }

        return {
            logout, props
        }
    }
}
</script>