<template>
    <header id="home-header">
        <div class="event-logo">
            <img :src="`${base_url}${event.icon}`" alt="event-logo" />
        </div>
        <div class="event-title text-white">
            <div class="event-date">{{ new Date().toDateString()}}</div>
            <h3>{{ event.title }}</h3>
            <div class="event-status">
                <span :class="statusClass"></span><span style="text-transform: capitalize;">{{ event.status.toString().toLowerCase() }}</span>
            </div>
            <div style="text-transform: capitalize; display: flex; justify-content: center; margin-top: 1rem;">
                <i class="fas fa-user" style="margin-right: .5rem;"></i>Hi, You have logged in as: {{ voter.name }}
            </div>
        </div>
    </header>
    <main id="home-main">
        <div v-if="error" class="alert alert-danger">
            <p>{{ alertText }}</p>
        </div>
        <div v-if="success" class="alert alert-success">
            <p>{{ alertText }}</p>
        </div>
        <div class="voting-live pulse text-white" v-if="event.id && event.status == 'ACTIVE'">Voting is live! Vote now before: {{ event.event_time_end}}</div>
        <div class="voting-time text-white" v-else-if="event.status == 'CLOSED'">Voting has been CLOSED</div>
        <div class="voting-time text-white" v-else>Voting begins {{ !isToday(event.event_date) ? `on ${event.event_date}`: '' }} at: {{ event.event_time_start }}</div>
        <div class="stats text-white">
            <div class="positions-stats">
                <div style="display: flex; align-items: center;">
                    <div class="stats-count">{{ event.positions_count }}</div>
                    <div class="stats-text">Positions vied for.</div>
                </div>
                <div class="more-link" @click="navigateTo('/positions')">
                    <a to="/positions" @click.prevent="">view positions</a>
                    <span class="arrow"> </span>
                </div>
            </div>
            <div class="positions-stats">
                <div style="display: flex; align-items: center;">
                    <div class="stats-count">{{ event.candidates_count }}</div>
                    <div class="stats-text">Candidates running.</div>
                </div>
                <div class="more-link" @click="navigateTo('/vote')">
                    <a to="/vote" @click.prevent="">view candidates</a>
                    <span class="arrow"> </span>
                </div>
            </div>
        </div>
        <div style="margin: 2rem auto;" v-if="!store.getters.isEventClosed">
            <router-link id="vote-link" to="/vote">VOTE NOW</router-link>
        </div>
        <div style="margin: 2rem auto;" class="text-white" v-else>
            <h3>Voting has ended for this event!</h3>
        </div>
    </main>
    <MenuBar path="/home" />
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import MenuBar from "../components/MenuBar.vue";
import useUser from '@/mixins/useUser.js';

export default {
    name: "Home",
    setup() {
        const route = useRoute();
        const router = useRouter();
        const isAuth = route.meta.isAuth;
        const store = useStore();
        const error = ref(false);
        const success = ref(false);
        const alertText = ref("");
        const { checkLogin } = useUser();

        onMounted(async () => {
            if (isAuth) {
                //perform auth
                const voter = {
                    email: "use_voter_id",
                    event: route.params.event.trim(),
                    id: route.params.voter.trim(),
                    auth_code: route.params.authcode.trim(),
                };
                const res = await store.dispatch("login", voter);
                if (res) {
                    router.push("/home");
                }
                else {
                    error.value = true;
                    alertText.value = "Wrong credentials entered";
                    router.push("/");
                }
            }
            else{
                await initUI();
            }
        });
        const initUI = async () => {
            // set title
            document.title = `Jubatus Voting App - Home`;

            // if store.loggedIn is false
            checkLogin();
        };
        
        const statusClass = computed(() => {
            if (store.state.event.status == "ACTIVE") {
                return "status-green";
            }
            if (store.state.event.status == "CLOSED") {
                return "status-grey";
            }
            if (store.state.event.status == "SCHEDULED") {
                return "status-orange";
            }
            return "status-red";
        });

        function navigateTo(path){
            router.push(path);
        }

        function isToday(date){
            let today = new Date().toLocaleDateString();
            date = date.replace(/-/g, '/');
            let ed = new Date(Date.parse(date)).toLocaleDateString();
            //console.log(today, ed);

            return today === ed;
        }

        return {
            error,
            success,
            alertText,
            event: computed(() => store.state.event),
            base_url: process.env.VUE_APP_BASE_URL,
            statusClass,
            voter: computed(() => store.state.voter),
            navigateTo,
            isToday,
            store,
        };
    },
    components: { MenuBar }
}
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/home.css';
</style>