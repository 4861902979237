<template>
    <HeaderComp />
    <main id="about-main" v-if="candidate">
        <div class="candidate-header">
            Candidate: {{candidate.title}}. {{ candidate.fullname }}
        </div>
        <div class="candidate-content">
            <div class="candidate-photo">
                <img :src="`${base_url}${candidate.avatar}`" :alt="`candidate-photo-for-${candidate.fullname}`" />
            </div>
            <div class="candidate-titles">
                <span>Running for: <b>{{ store.getters.getPositionName(candidate.position_id) }}</b></span>
            </div>
            <div class="candidate-titles">
                <span class="current-position" v-if="candidate.current_role.length">Current position: <b>{{ candidate.current_role }}</b></span>
                <span class="slogan" v-if="candidate.slogan.length">Slogan: <em>"{{ candidate.slogan }}!"</em></span>
            </div>
            <div class="candidate-about">
                <p v-if="candidate.about.length">
                   {{ candidate.about }} 
                </p>
                <p v-else>
                    About candidate...
                </p>
            </div>
            <div class="back-link">
                <router-link to="/vote"> &lArr; Go back</router-link>
            </div>
        </div>
    </main>
    <div v-else>
        Loading candidate information...
    </div>
    <MenuBar path="/about_candidate" />
</template>

<script>
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import MenuBar from "../components/MenuBar.vue";
import HeaderComp from "../components/HeaderComp.vue";
export default {
    components: { MenuBar, HeaderComp },
    setup(){
        const store = useStore();
        const id = useRoute().params.id;

        if(store.state.candidates.length < 1){
            try {
                store.dispatch('getCandidates');
            } catch (error) {
                console.log(error.message);
            }
        }

        return {
            candidate: store.getters.getCandidateById(id),
            base_url: process.env.VUE_APP_BASE_URL,
            store,
        }
    },
}
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/about_candidate.css';
</style>