<template>
    <HeaderComp />
    <main id="vote-main">
        <div class="candidate-header" id="vote-header-title">
            Candidates
        </div>
        <div class="candidates-content">
            <ul class="positions-list">
                <li v-for="(pos, i) in positions" :key="pos.id" :id="`pos-${i}-list`">
                    <div class="position-name">
                        {{ ++i }}. {{ pos.name }} {{ pos.voted_for ? '(Already Voted For)' : ''}}
                    </div>
                    <div class="candidates-display">
                        <div class="candidate" v-for="(candidate) in store.getters.getCandidatesByPosition(pos.id)" :key="candidate.fullname">
                            <div class="candidate-photo">
                                <img :src="`${base_url}${candidate.avatar}`" :alt="`candidate-photo-for-${candidate.fullname}`" />
                            </div>
                            <div class="candidate-name">
                                {{candidate.title}}. {{ candidate.fullname }}
                            </div>
                            <div class="about-link">
                                <router-link :to="{name:'about', params: {id: candidate.id}}">About Candidate</router-link>
                            </div>
                            <div class="vote-button">
                                <button v-if="candidate.voted_for" type="button" class="voted-btn">voted</button>
                                <button v-if="!pos.voted_for && !store.getters.isEventClosed" type="button" class="btn"
                                @click="voteFor(candidate, pos)"
                                >{{ candidate.uiState.voting ? "Voting..." : "VOTE" }}
                                </button>
                                <button class="btn"
                                v-if="!pos.voted_for && store.getters.isEventClosed" 
                                type="button" style="cursor: not-allowed; pointer-events: none;" disabled>
                                closed
                                </button>
                            </div>
                            <div class="alert alert-danger" style="border-radius: 0;" v-if="candidate.uiState.error">
                                <p style="padding: 0;">{{ candidate.uiState.alertText }}</p>
                            </div>
                            <div class="alert alert-success" v-if="candidate.uiState.success">
                                <p>{{ candidate.uiState.alertText }}</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </main>
    <MenuBar path="/vote" />
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import MenuBar from "../components/MenuBar.vue"
import HeaderComp from "../components/HeaderComp.vue";
import useUser from "../mixins/useUser";

export default {
    setup() {
        const store = useStore();

        const scrollTo = () => {
            let str = location.hash.slice(1);
            if(str){
                var elmnt = document.getElementById(str);
                elmnt.scrollIntoView();
            }
        };
        const {checkLogin} = useUser();

        onMounted(async () => {
            checkLogin();

            document.title = "Jubatus Voting App - Voting Page";

            try {
                if(store.state.candidates.length > 0) return;
                await store.dispatch('getCandidates');
            } catch (error) {
                console.log(error.message);
            }
            scrollTo();
        });

        const voteFor = async (candidate, position) => {
            if(store.getters.isEventClosed){
                alert('Voting has ended for this event');
                return;
            }
            else if(store.state.event.status !== 'ACTIVE'){
                alert('Voting has not started yet!');
                return;
            }
            else{
                let str = `Proceed to vote for ${candidate.fullname} for ${position.name} position?`;
                if(confirm(str)){
                    var date = new Date();
                    const vote = {
                        event_id: store.state.event.id,
                        cast_at: date.toISOString().slice(0, 19).replace('T', ' '),
                        voter_id: store.state.voter.id,
                        position_id: position.id,
                        candidate_id: candidate.id,
                    };

                    candidate.uiState.success = candidate.uiState.error = false;
                    candidate.uiState.voting = true;

                    const [success, message] = await store.dispatch('castVote', vote);

                    candidate.uiState.voting = false;

                    if(success){
                        candidate.uiState.success = success;
                        candidate.uiState.alertText = message;

                        candidate.voted_for = true;
                        position.voted_for = true;

                        store.commit('updateCandidate', candidate);
                        store.commit('updatePosition', position);
                    }
                    else{
                        candidate.uiState.error = true;
                        candidate.uiState.alertText = message;
                    }
                }
            }
        }

        return {
            candidates: computed(() => store.state.candidates), 
            positions: computed(() => store.state.positions),
            store,
            base_url: process.env.VUE_APP_BASE_URL,
            voteFor,
        };
    },
    components: { MenuBar, HeaderComp }
}
</script>

<style scoped>
@import '../assets/css/candidates.css';
@import '../assets/css/common.css';

.voted-btn {
    cursor:not-allowed;
    padding: .5rem 1rem;
    background: #ccc;
    font-weight: 500;
    text-transform: capitalize;
    color: white;
    background: orangered;
    border: none;
}
</style>