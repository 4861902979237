import { createStore } from "vuex";
import axiosClient from "../axios";

export default createStore({
    state(){
        return {
            voter: JSON.parse(localStorage.getItem('voter')) || {
                id: 0,
                name: ''
            },
            event: JSON.parse(localStorage.getItem('voting-event')) || {
                id:0,
                title: '',
                status: '',
                icon: '/events/icons/default.png',
                event_date: '',
                event_time_start: '',
                event_time_end: '',
            },
            positions: [],
            candidates: [],
            eventError: false,
            loggedIn: JSON.parse(localStorage.getItem('loggedIn')) || false,
            refresher: false,
            votes: [],
            refreshError: false,
        }
    },
    mutations: {
        setEvent(state, event){
            localStorage.setItem('voting-event', JSON.stringify(event));
            state.event = event;
        },
        setEventError(state, val){
            state.eventError = val;
            setTimeout(function(){ state.eventError = false;});
        },
        setRefreshError(state, val){
            state.refreshError = val;
        },
        setVoter(state, voter){
            localStorage.setItem('voter', JSON.stringify(voter));
            state.voter = voter;
        },
        setLoggedIn(state, val){
            localStorage.setItem('loggedIn', JSON.stringify(val));
            state.loggedIn = val;
        },
        setRefresher(state, val){
            state.refresher = val;
        },
        clearState(state){
            state.voter = { id: 0, name: ''};
            state.event = {
                id:0,
                title: '',
                status: '',
                icon: '/events/icons/default.png',
                event_date: '',
                event_time_start: '',
                event_time_end: '',
            };
            state.loggedIn = false;

            localStorage.clear();
        },
        setPositions(state, positions){
            state.positions = positions;
        },
        setCandidates(state, candidates){
            state.candidates = candidates;
        },
        addToVotes(state, vote){
            state.votes.push(vote);
        },
        updatePosition(state, position){
            let idx = state.positions.findIndex(({id}) => id == position.id);
            state.positions.splice(idx, 1, position);
        },
        updateCandidate(state, candidate){
            let idx = state.candidates.findIndex(({id}) => id == candidate.id);
            state.candidates.splice(idx, 1, candidate);
        },
    },
    getters: {
        getCandidatesByPosition: (state) => (position) => {
            return state.candidates.filter(candidate => candidate.position_id == position);
        },
        getCandidateById: (state) => (candidate) => {
            return state.candidates.find(({id}) => id == candidate);
        },
        getPositionName: (state) => (position_id) => {
            let pos = state.positions.find(({id}) => id == position_id);

            return pos.name;
        },
        isEventClosed(state){
            return state.event.status == 'CLOSED';
        }
    },
    actions: {
        async castVote(context, vote){
            try {
                if(context.state.event.status !== 'ACTIVE') throw new Error('The event status is not active!');

                await axiosClient.get('/sanctum/csrf-cookie');

                const res = await axiosClient.post(`/api/voters/vote`, vote, { headers: {
                    'accept':'application/json',
                    'Access-Control-Allow-Origin': 'https://app.voting.jubatus.co.ke'
                }});

                if(res.data.success == 1){
                    context.commit('addToVotes', res.data.vote);
                    return [true, res.data.message];
                }
                else{
                    return [false, res.data.message];
                }
            } catch (error) {
                console.log(error);
                return [false, error.message];
            }
        },
        async getCandidates({state, commit, dispatch}){
            try {
                if(state.event.id == 0){
                    throw Error('Event is not loaded');
                }
                
                if(! state.positions.length) await dispatch('getPositions');

                const res = await axiosClient.post(`/api/voters/positions/${state.event.id}/candidates`,{
                        voter: state.voter.id,
                        event: state.event.id,
                    }, 
                    {
                        headers: {
                            'accept':'application/json',
                            'Access-Control-Allow-Origin': 'https://app.voting.jubatus.co.ke'
                        }
                    });

                if(res.data.success == 1){
                    commit('setCandidates', res.data.candidates);
                    //return true;
                }
                else{
                    commit('setEventError', true);
                    //return false;
                }
            } catch (e) {
                console.log(e);
                commit('setEventError', true);
            }
        },
        async getPositions({state, commit}){
            try {
                if(state.event.id == 0){
                    throw Error('Event is not loaded');
                }

                //await axiosClient.get('/sanctum/csrf-cookie');

                const res = await axiosClient.post(`/api/voters/events/${state.event.id}/positions`,{voter: state.voter.id,}, { headers: {
                    'accept':'application/json',
                   'Access-Control-Allow-Origin': 'https://app.voting.jubatus.co.ke',
                }});

                if(res.data.success == 1){
                    commit('setPositions', res.data.positions);
                    //return true;
                }
                else{
                    commit('setEventError', true);
                    //return false;
                }
            } catch (e) {
                console.log(e);
                commit('setEventError', true);
            }
        },
        login: function({commit}, voter){
            try {
                return axiosClient.get('/sanctum/csrf-cookie')
                .then(function(){
                return axiosClient.post(`/api/voters/login`, voter, { headers: {
                        'accept':'application/json',
                        'Access-Control-Allow-Origin': 'https://app.voting.jubatus.co.ke'
                    }})
                    .then(function(res){
                        if(res.data.success == 1){
                            commit('setVoter', res.data.voter);
                            commit('setLoggedIn', true);
                            commit('setEvent', res.data.event);
                            return [true, res.data.message];
                        }
                        else{
                            return [false, res.data.message];
                        }
                    });
                }).catch(function(e){
                    console.log(e);
                    return [false, e.message];
                });
            } catch (error) {
                return [false, error.message];
            }
        },
        async getEventDetails({commit}, eventCode){
            try {
                if(eventCode == undefined) throw new Error('Event code not specified!');

                await axiosClient.get('/sanctum/csrf-cookie');

                const res = await axiosClient.get(`/api/voters/events/${eventCode}`, { headers: {
                    'accept':'application/json',
                }});

                if(res.data.success == 1){
                    commit('setEvent', res.data.event);
                    //return true;
                }
                else{
                    commit('setEventError', true);
                    //return false;
                }
            } catch (e) {
                console.log(e);
                commit('setEventError', true);
            }
        },

        async refreshEvent({commit, state}, id){
            if(state.refreshError) return;
            if(!state.refresher) return;

            try {
                if(!id) throw new Error('Event code not specified!');

                await axiosClient.get('/sanctum/csrf-cookie');

                const res = await axiosClient.get(`/api/voters/event/${id}`, { headers: {
                    'accept':'application/json',
                }});

                if(res.data.success == 1){
                    commit('setEvent', res.data.event);
                    //return true;
                }
                else{
                    commit('setEventError', true);
                    //return false;
                }
            } catch (e) {
                commit('setRefreshError', true);
                if(e.response){
                    if(e.response.status == 401 || e.response.status == 419)
                    {
                        commit('clearState');
                        alert('Your session is not valid, logout of the app and login afresh');
                    }
                }
                else{
                    alert(e.message + ". Reload the page and try again after 1 minute.");
                    console.log(e);
                }
            }
        },

        async registerVoter({commit}, voter){
            try {
                if(voter.unique_id == '') throw new Error('Voter not set!');

                //await axiosClient.get('/sanctum/csrf-cookie');

                const res = await axiosClient.post(`/api/voters/register`, voter, { headers: {
                    'accept':'application/json',
                    'Access-Control-Allow-Origin': 'https://app.voting.jubatus.co.ke'
                }});

                if(res.data.success == 1){
                    commit('setVoter', res.data.voter);
                    return [true, res.data.message];
                }
                else{
                    return [false, res.data.message];
                }
            } catch (error) {
                console.log(error);
                return [false, error.message];
            }
        },
    },
});