<template>
    <HeaderComp />
    <main id="positions-main">
        <div class="position-header" id="pos-header-title">
            Positions
        </div>
        <div class="position-content">
            <ul v-if="positions.length > 0">
                <li class="text-white" v-for="(pos, i) in positions" :key="pos.name">{{1 + i}}. {{ pos.name }}
                    <router-link class="text-white" :to="`/vote#pos-${i}-list`">view candidates <div class="triangle"></div></router-link>
                </li>
            </ul>
            <ul v-else>
                <li>
                    Loading positions...
                </li>
            </ul>
            <div v-if="loadError" class="alert alert-danger">
                <p>Failed to load positions</p>
            </div>
        </div>
    </main>
    <MenuBar path="/positions" />
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import MenuBar from "../components/MenuBar.vue";
import HeaderComp from "../components/HeaderComp.vue";
import useUser from "../mixins/useUser";

export default {
    components: { MenuBar, HeaderComp },
    setup(){
        const store = useStore();
        const {checkLogin} = useUser();

        //  if(store.getters.isEventClosed){
        //     alert('Voting has ended for this event');
        //     router.push('/home');
        // }

        onMounted(async ()=> {
            checkLogin();
            document.title = "Jubatus Voting App - Viewing Positions";

            try {
                if(store.state.positions.length > 0) return;
                
                await store.dispatch('getPositions');
            } catch (error) {
                console.log(error.message);
            }
        });

        return {
            positions: computed(() => store.state.positions),
            loadError: computed(() => store.state.eventError),
        }
    },
}
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/positions.css';
</style>