<template>
    <header>
        <div class="event-logo">
            <img :src="`${base_url}${event.icon}`" alt="event-logo" />
        </div>
        <div class="event-title text-white">
            <h3>{{ event.title }}</h3>
            <div class="event-status">
                <span :class="statusClass"></span><span style="text-transform: capitalize;">{{ event.status.toString().toLowerCase() }}</span>
            </div>
            <div class="vote-live pulse" v-if="event.status == 'ACTIVE'">
                Voting is live! Vote now
            </div>
        </div>
    </header>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex"

export default {
    setup(){
        const store = useStore();

        const statusClass = computed(() => {
            if (store.state.event.status == "ACTIVE") {
                return "status-green";
            }
            if (store.state.event.status == "CLOSED") {
                return "status-grey";
            }
            if (store.state.event.status == "SCHEDULED") {
                return "status-orange";
            }
            return "status-red";
        });

        return {
            event: computed(() => store.state.event),
            base_url: process.env.VUE_APP_BASE_URL,
            statusClass,
        }
    }
}
</script>

<style scoped>
header {
    background: var(--bg-dark);
    height: max-content;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: .5rem 1rem;
}

.event-logo {
    width: 40%;
}

.event-logo > img {
    width: 120px;
    object-fit: cover;
    border: 2px solid white;
}

span.status-green {
    display: inline-block;
    background: rgb(0, 199, 0);
    width: 15px; height: 15px;
    border-radius: 50%;
    margin: 0 .5rem;
}

span.status-grey {
    display: inline-block;
    background: grey;
    width: 15px; height: 15px;
    border-radius: 50%;
    margin: 0 .5rem;
}
span.status-red {
    display: inline-block;
    background: red;
    width: 15px; height: 15px;
    border-radius: 50%;
    margin: 0 .5rem;
}
span.status-orange {
    display: inline-block;
    background: orange;
    width: 15px; height: 15px;
    border-radius: 50%;
    margin: 0 .5rem;
}

.event-title {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.event-title > .event-date {
    font-size: .9rem;
    align-self: flex-end;
}

.event-title > h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: .5rem;
    text-transform: capitalize;
}

.event-title > .event-status {
    display: flex;
    align-items: center;
}

/* Animation when event is live */
.vote-live {
    background: rgb(241, 58, 26);
    font-size: .9rem;
    font-weight: 400;
    text-align: center;
    padding: .2rem 0.5rem;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: .2rem;
    width: fit-content;
    margin: .5rem auto;
    box-shadow: 0px 0px 1px 1px rgba(241, 58, 26, .9);/*#0000001a;*/
}

.pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
      transform: scale(1.1);
    }
    50%{
        transform: scale(1);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}
/* Animation when event is live */

@media screen and (max-width:600px) {

    header {
        height: auto;
        width: 100%;
        flex-direction: column;
    }

    .event-title {
        justify-content: center;
         width: auto;
    }
    .event-logo {
        width: auto;
    }
}

</style>